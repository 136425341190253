import { Permission } from 'src/app/services/permission/index'
import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { Observable, of, empty, from } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap } from 'rxjs/operators'
import store from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as UserActions from './actions'
import { jwtAuthService } from 'src/app/services/jwt'
import { Console } from 'console'
import * as moment from 'moment'
import { LocalService } from 'src/app/services/local.service'

@Injectable()
export class UserEffects implements OnInitEffects {
  constructor(
    private actions: Actions,
    private jwtAuthService: jwtAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
    private permission: Permission,
    private localService: LocalService,
  ) {}
  settings = {}
  ngrxOnInitEffects(): Action {
    this.settings = this.permission.loginSettings()
    return { type: store.get('accessToken') ? UserActions.LOAD_CURRENT_ACCOUNT : '' }
  }

  @Effect()
  login: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGIN),
    map((action: UserActions.Login) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      // jwt login
      if (settings.authProvider === 'jwt') {
        return this.jwtAuthService.login(payload.email, payload.password, payload.ipAddress).pipe(
          map(response => {
            this.localService.setJsonValue('login_time', moment(new Date()))
            this.localService.setJsonValue('userInfo', JSON.stringify(response))
            this.localService.setJsonValue('SessionId', response.SessionId)

            let dateTimerInterval = parseFloat(this.settings['SESSION_TIMEOUT']) * 60 * 1000
            let lastTime = new Date().getTime()
            this.localService.setJsonValue('session_expiry', lastTime + dateTimerInterval)
            if (response.IsForceChangePassword === '1') {
              this.router.navigate(['/auth/forgot-password/setup-password']) // redirect to change pw
            } else {
              this.router.navigate(['/dashboard']) // redirect to root route on auth pages
            }

            /* [temporary condition] remove failed attempt of user (added by prince) */
            store.remove(`failed_${this.localService.getJsonValue('userEmail')}`)
            /* ends here */

            return new UserActions.LoginSuccessful('') // success login even if failed to fetch user data..
          }),
          catchError(err => {
            const max_attempt = this.settings['MAX_ATTEMP']
            const timeout = this.settings['USER_LOCK_TIMEOUT']
            const dailyReset = this.settings['DAILY_LIMIT_PASSWORD']
            const error = err.error
            if (error.IsUserLock == '1') {
              this.notification.warning(
                'Too Many Failed Login Attempts',
                `You have had too many failed login attempts within a short period of time. Please try again in ${Number(
                  error.RemainingTime,
                ).toFixed(0)} minutes`,
              )
              return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }])
            } else {
              this.notification.warning(
                'Auth Failed',
                `Please check your ${this.settings['USERNAME_LABEL']} or ${this.settings['PASSWORD_LABEL']}.`,
              )
              return from([{ type: UserActions.LOGIN_UNSUCCESSFUL }])
            }
          }),
        )
      }
    }),
  )

  @Effect()
  register: Observable<any> = this.actions.pipe(
    ofType(UserActions.REGISTER),
    map((action: UserActions.Register) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      // jwt register
      if (settings.authProvider === 'jwt') {
        return this.jwtAuthService.register(payload.email, payload.password, payload.name).pipe(
          map(response => {
            if (response && response.id) {
              if (response.accessToken) {
                store.set('accessToken', response.accessToken)
              }
              this.router.navigate(['/'])
              return new UserActions.RegisterSuccessful(response)
            }
            this.notification.warning('Registration Failed', response)
            return new UserActions.RegisterUnsuccessful()
          }),
        )
      }
    }),
  )

  @Effect()
  loadCurrentAccount: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOAD_CURRENT_ACCOUNT),
    map((action: UserActions.LoadCurrentAccount) => action),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([action, settings]) => {
      // jwt load current account
      if (settings.authProvider === 'jwt') {
        return this.jwtAuthService.currentAccount().pipe(
          map(response => {
            return new UserActions.LoadCurrentAccountSuccessful(response) // success login even if failed to fetch user data..
          }),
          catchError(error => {
            return from([{ type: UserActions.LOAD_CURRENT_ACCOUNT_UNSUCCESSFUL }])
          }),
        )
      }
      // do nothing for firebase, as user state subscribed inside firebase service
      return of(new UserActions.EmptyAction())
    }),
  )

  @Effect()
  logout: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGOUT),
    map((action: UserActions.Logout) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([, settings]) => {
      // jwt logout
      return this.jwtAuthService.logout().pipe(
        map(res => {
          // not success
          this.router.navigate(['/auth/login'])
          return new UserActions.LogoutSuccessful('')
        }),
        catchError(error => {
          return from([{ type: UserActions.LOGOUT_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  forgotPassword: Observable<any> = this.actions.pipe(
    ofType(UserActions.FORGOT_PASSWORD),
    map((action: UserActions.ForgotPassword) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      if (settings.authProvider === 'jwt') {
        return this.jwtAuthService.forgotPassword(payload.email).pipe(
          map(response => {
            this.notification.success('Success', response.Message)
            this.router.navigate(['/auth/login'])
            return new UserActions.ForgotPasswordSuccessful(response)
          }),
          catchError(err => {
            let error = err.error.Message
            this.notification.warning('Error', error)
            return from([{ type: UserActions.SET_PASSWORD_UNSUCCESSFUL }])
          }),
        )
      }
    }),
  )
  @Effect()
  setPassword: Observable<any> = this.actions.pipe(
    ofType(UserActions.SET_PASSWORD),
    map((action: UserActions.SetPassword) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      if (settings.authProvider === 'jwt') {
        return this.jwtAuthService.setPassword(payload).pipe(
          map(response => {
            this.notification.success('Success', response.Message)
            this.router.navigate(['/auth/forgot-password/success'])
            return new UserActions.SetPasswordSuccessful(response)
          }),
          catchError(err => {
            let error = err.error.Message
            this.notification.warning('Error', error)
            return from([{ type: UserActions.SET_PASSWORD_UNSUCCESSFUL }])
          }),
        )
      }
    }),
  )
  @Effect()
  getLoginSettings: Observable<any> = this.actions.pipe(
    ofType(UserActions.LOGIN_SETTINGS),
    map((action: UserActions.LoginSettings) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.jwtAuthService.getLoginSettings().pipe(
        map(res => {
          res.LoginSettings = res.Data
          return new UserActions.LoginSettingsSuccessful(res)
        }),
        catchError(error => {
          // let err = error.error
          // this.notification.warning('Error', `${err.Message}`)
          return from([{ type: UserActions.LOGIN_SETTINGS_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  setIPAddress: Observable<any> = this.actions.pipe(
    ofType(UserActions.SET_IPADDRESS),
    map((action: UserActions.SetIPAddress) => action.payload),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      if (settings.authProvider === 'jwt') {
        return this.jwtAuthService.setIPAddress(payload).pipe(
          map(response => {
            this.notification.success('Success', response.Message)
            this.router.navigate(['/auth/forgot-password/success'])
            return new UserActions.SetIPAddressSuccessful(response)
          }),
          catchError(err => {
            let error = err.error.Message
            this.notification.warning('Error', error)
            return from([{ type: UserActions.SET_PASSWORD_UNSUCCESSFUL }])
          }),
        )
      }
    }),
  )
}

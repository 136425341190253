import { previewAgreement } from './../reducers'
import { Action } from '@ngrx/store'

export const RESET = 'Reset'

export const PLAYER_LIST = 'Get PlayerList'
export const PLAYER_LIST_SUCCESSFUL = 'Get PlayerList Successful'
export const PLAYER_LIST_UNSUCCESSFUL = 'Get PlayerList Unsuccessful'

export const PLAYER_DETAILS = 'Get PlayerDetails'
export const PLAYER_DETAILS_SUCCESSFUL = 'Get PlayerDetails Successful'
export const PLAYER_DETAILS_UNSUCCESSFUL = 'Get PlayerDetails Unsuccessful'

export const PLAYER_FEE_INFO = 'Get Player Fee Info'
export const PLAYER_FEE_INFO_SUCCESSFUL = 'Get Player Fee Info Successful'
export const PLAYER_FEE_INFO_UNSUCCESSFUL = 'Get Player Fee Info Unsuccessful'

export const PLAYER_MARKER_INFO = 'Get Player Marker Info'
export const PLAYER_MARKER_INFO_SUCCESSFUL = 'Get Player Marker Info Successful'
export const PLAYER_MARKER_INFO_UNSUCCESSFUL = 'Get Player Marker Info Unsuccessful'

export const MAKE_PAYMENT = 'Make Payment'
export const MAKE_PAYMENT_SUCCESSFUL = 'Make Payment Successful'
export const MAKE_PAYMENT_UNSUCCESSFUL = 'Make Payment Unsuccessful'

export const CASHOUT = 'Cashout'
export const CASHOUT_SUCCESSFUL = 'Cashout Successful'
export const CASHOUT_UNSUCCESSFUL = 'Cashout Unsuccessful'

export const MAKE_ADJUSTMENT = 'Make Adjustment'
export const MAKE_ADJUSTMENT_SUCCESSFUL = 'Make Adjustment Successful'
export const MAKE_ADJUSTMENT_UNSUCCESSFUL = 'Make Adjustment Unsuccessful'

export const PLAYER_ACT_LIST = 'Get Player Act. List'
export const PLAYER_ACT_LIST_SUCCESSFUL = 'Get Player Act. List Successful'
export const PLAYER_ACT_LIST_UNSUCCESSFUL = 'Get Player Act. List Unsuccessful'

export const LIMIT_ACT_LIST = 'Get Limit Act. List'
export const LIMIT_ACT_LIST_SUCCESSFUL = 'Get Limit Act. List Successful'
export const LIMIT_ACT_LIST_UNSUCCESSFUL = 'Get Limit Act. List Unsuccessful'

export const FEE_ACT_LIST = 'Get Fee Act. List'
export const FEE_ACT_LIST_SUCCESSFUL = 'Get Fee Act. List Successful'
export const FEE_ACT_LIST_UNSUCCESSFUL = 'Get Fee Act. List Unsuccessful'

export const PAYMENT_ACT_LIST = 'Get Payment Act. List'
export const PAYMENT_ACT_LIST_SUCCESSFUL = 'Get Payment Act. List Successful'
export const PAYMENT_ACT_LIST_UNSUCCESSFUL = 'Get Payment Act. List Unsuccessful'

export const STATEMENT = 'Get Statement List'
export const STATEMENT_SUCCESSFUL = 'Get Statement List Successful'
export const STATEMENT_UNSUCCESSFUL = 'Get Statement List Unsuccessful'

export const PLAYER_AGREEMENT = 'Set Player Agreement'
export const PLAYER_AGREEMENT_SUCCESSFUL = 'Set Player Agreement Successful'
export const PLAYER_AGREEMENT_UNSUCCESSFUL = 'Set Player Agreement Unsuccessful'

export const GENERATE_AGREEMENT = 'Generate Agreement'
export const GENERATE_AGREEMENT_SUCCESSFUL = 'Generate Agreement Successful'
export const GENERATE_AGREEMENT_UNSUCCESSFUL = 'Generate Agreement Unsuccessful'

export const PREVIEW_AGREEMENT = 'Preview Agreement'
export const PREVIEW_AGREEMENT_SUCCESSFUL = 'Preview Agreement Successful'
export const PREVIEW_AGREEMENT_UNSUCCESSFUL = 'Preview Agreement Unsuccessful'

export const GENERATE_STATEMENT = 'Generate Statement'
export const GENERATE_STATEMENT_SUCCESSFUL = 'Generate Statement Successful'
export const GENERATE_STATEMENT_UNSUCCESSFUL = 'Generate Statement Unsuccessful'

export const CREDIT_FACTOR = 'Get Credit Factor'
export const CREDIT_FACTOR_SUCCESSFUL = 'Get Credit Factor Succesful'
export const CREDIT_FACTOR_UNSUCCESSFUL = 'Get Credit Factor Unsuccesful'

export const INTERNAL_ADJUSTMENT = 'Internal Adjustment'
export const INTERNAL_ADJUSTMENT_SUCCESSFUL = 'Internal Adjustment Successful'
export const INTERNAL_ADJUSTMENT_UNSUCCESSFUL = 'Internal Adjustment Unsuccessful'

export const PATRON_NOTIF_LIST = 'Patron History'
export const PATRON_NOTIF_LIST_SUCCESSFUL = 'Patron History Successful'
export const PATRON_NOTIF_LIST_UNSUCCESSFUL = 'Patron History Unsuccessful'

/* player list */
export class PlayerList implements Action {
  readonly type = PLAYER_LIST
  constructor() {}
}
export class PlayerListSuccess implements Action {
  readonly type = PLAYER_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerListUnSuccess implements Action {
  readonly type = PLAYER_LIST_UNSUCCESSFUL
  constructor() {}
}

/* player details */
export class PlayerDetails implements Action {
  readonly type = PLAYER_DETAILS
  constructor(public data: any) {}
}
export class PlayerDetailsSuccess implements Action {
  readonly type = PLAYER_DETAILS_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerDetailsUnSuccess implements Action {
  readonly type = PLAYER_DETAILS_UNSUCCESSFUL
  constructor() {}
}

/* player fee info */
export class PlayerFeeInfo implements Action {
  readonly type = PLAYER_FEE_INFO
  constructor(public data: any) {}
}
export class PlayerFeeInfoSuccess implements Action {
  readonly type = PLAYER_FEE_INFO_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerFeeInfoUnSuccess implements Action {
  readonly type = PLAYER_FEE_INFO_UNSUCCESSFUL
  constructor() {}
}

/* player fee info */
export class PlayerMarkerInfo implements Action {
  readonly type = PLAYER_MARKER_INFO
  constructor(public data: any) {}
}
export class PlayerMarkerInfoSuccess implements Action {
  readonly type = PLAYER_MARKER_INFO_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerMarkerInfoUnSuccess implements Action {
  readonly type = PLAYER_MARKER_INFO_UNSUCCESSFUL
  constructor() {}
}

/* Make Payment */
export class MakePayment implements Action {
  readonly type = MAKE_PAYMENT
  constructor(public data: any) {}
}
export class MakePaymentSuccess implements Action {
  readonly type = MAKE_PAYMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class MakePaymentUnSuccess implements Action {
  readonly type = MAKE_PAYMENT_UNSUCCESSFUL
  constructor() {}
}

/* Make Adjustment */
export class MakeAdjustment implements Action {
  readonly type = MAKE_ADJUSTMENT
  constructor(public data: any) {}
}
export class MakeAdjustmentSuccess implements Action {
  readonly type = MAKE_ADJUSTMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class MakeAdjustmentUnSuccess implements Action {
  readonly type = MAKE_ADJUSTMENT_UNSUCCESSFUL
  constructor() {}
}

/* player act. list */
export class PlayerActList implements Action {
  readonly type = PLAYER_ACT_LIST
  constructor(public data: any) {}
}
export class PlayerActListSuccess implements Action {
  readonly type = PLAYER_ACT_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerActListUnSuccess implements Action {
  readonly type = PLAYER_ACT_LIST_UNSUCCESSFUL
  constructor() {}
}

/* limit act. list */
export class LimitActList implements Action {
  readonly type = LIMIT_ACT_LIST
  constructor(public data: any) {}
}
export class LimitActListSuccess implements Action {
  readonly type = LIMIT_ACT_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class LimitActListUnSuccess implements Action {
  readonly type = LIMIT_ACT_LIST_UNSUCCESSFUL
  constructor() {}
}

/* fee act. list */
export class FeeActList implements Action {
  readonly type = FEE_ACT_LIST
  constructor(public data: any) {}
}
export class FeeActListSuccess implements Action {
  readonly type = FEE_ACT_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class FeeActListUnSuccess implements Action {
  readonly type = FEE_ACT_LIST_UNSUCCESSFUL
  constructor() {}
}

/* payment act. list */
export class PaymentActList implements Action {
  readonly type = PAYMENT_ACT_LIST
  constructor(public data: any) {}
}
export class PaymentActListSuccess implements Action {
  readonly type = PAYMENT_ACT_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class PaymentActListUnSuccess implements Action {
  readonly type = PAYMENT_ACT_LIST_UNSUCCESSFUL
  constructor() {}
}

/* statement list */
export class StatementList implements Action {
  readonly type = STATEMENT
  constructor(public data: any) {}
}
export class StatementListSuccess implements Action {
  readonly type = STATEMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class StatementListUnSuccess implements Action {
  readonly type = STATEMENT_UNSUCCESSFUL
  constructor() {}
}

/* player agreement */
export class PlayerAgreement implements Action {
  readonly type = PLAYER_AGREEMENT
  constructor(public data: any) {}
}
export class PlayerAgreementSuccess implements Action {
  readonly type = PLAYER_AGREEMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class PlayerAgreementUnSuccess implements Action {
  readonly type = PLAYER_AGREEMENT_UNSUCCESSFUL
  constructor() {}
}

/* generate agreement */
export class GenerateAgreement implements Action {
  readonly type = GENERATE_AGREEMENT
  constructor(public data: any) {}
}
export class GenerateAgreementSuccess implements Action {
  readonly type = GENERATE_AGREEMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class GenerateAgreementUnSuccess implements Action {
  readonly type = GENERATE_AGREEMENT_UNSUCCESSFUL
  constructor() {}
}

/* preview agreement */
export class PreviewAgreement implements Action {
  readonly type = PREVIEW_AGREEMENT
  constructor(public data: any) {}
}
export class PreviewAgreementSuccess implements Action {
  readonly type = PREVIEW_AGREEMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class PreviewAgreementUnSuccess implements Action {
  readonly type = PREVIEW_AGREEMENT_UNSUCCESSFUL
  constructor() {}
}
/* generate statement */
export class GenerateStatement implements Action {
  readonly type = GENERATE_STATEMENT
  constructor(public data: any) {}
}
export class GenerateStatementSuccess implements Action {
  readonly type = GENERATE_STATEMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class GenerateStatementUnSuccess implements Action {
  readonly type = GENERATE_STATEMENT_UNSUCCESSFUL
  constructor() {}
}

/* Credit Factor */
export class CreditFactor implements Action {
  readonly type = CREDIT_FACTOR
  constructor(public data: any) {}
}
export class CreditFactorSuccess implements Action {
  readonly type = CREDIT_FACTOR_SUCCESSFUL
  constructor(public data: any, public listType: any) {}
}
export class CreditFactorUnSuccess implements Action {
  readonly type = CREDIT_FACTOR_UNSUCCESSFUL
  constructor() {}
}

export class Reset implements Action {
  readonly type = RESET
  constructor() {}
}

/* Cash Out */
export class CashOut implements Action {
  readonly type = CASHOUT
  constructor(public data: any) {}
}
export class CashOutSuccess implements Action {
  readonly type = CASHOUT_SUCCESSFUL
  constructor(public data: any) {}
}
export class CashOutUnSuccess implements Action {
  readonly type = CASHOUT_UNSUCCESSFUL
  constructor() {}
}

/* InternalAdjustment */
export class InternalAdjustment implements Action {
  readonly type = INTERNAL_ADJUSTMENT
  constructor(public data: any) {}
}
export class InternalAdjustmentSuccess implements Action {
  readonly type = INTERNAL_ADJUSTMENT_SUCCESSFUL
  constructor(public data: any) {}
}
export class InternalAdjustmentUnSuccess implements Action {
  readonly type = INTERNAL_ADJUSTMENT_UNSUCCESSFUL
  constructor() {}
}
/* patron history list */
export class PatronNotifList implements Action {
  readonly type = PATRON_NOTIF_LIST
  constructor(public data: any) {}
}
export class PatronNotifListSuccess implements Action {
  readonly type = PATRON_NOTIF_LIST_SUCCESSFUL
  constructor(public data: any) {}
}
export class PatronNotifListUnSuccess implements Action {
  readonly type = PATRON_NOTIF_LIST_UNSUCCESSFUL
  constructor() {}
}

export type Actions =
  | Reset
  | PlayerList
  | PlayerListSuccess
  | PlayerListUnSuccess
  | PlayerDetails
  | PlayerDetailsSuccess
  | PlayerDetailsUnSuccess
  | PlayerFeeInfo
  | PlayerFeeInfoSuccess
  | PlayerFeeInfoUnSuccess
  | PlayerMarkerInfo
  | PlayerMarkerInfoSuccess
  | PlayerMarkerInfoUnSuccess
  | MakePayment
  | MakePaymentSuccess
  | MakePaymentUnSuccess
  | MakeAdjustment
  | MakeAdjustmentSuccess
  | MakeAdjustmentUnSuccess
  | PlayerActList
  | PlayerActListSuccess
  | PlayerActListUnSuccess
  | LimitActList
  | LimitActListSuccess
  | LimitActListUnSuccess
  | FeeActList
  | FeeActListSuccess
  | FeeActListUnSuccess
  | PaymentActList
  | PaymentActListSuccess
  | PaymentActListUnSuccess
  | PlayerAgreement
  | PlayerAgreementSuccess
  | PlayerAgreementUnSuccess
  | GenerateAgreement
  | GenerateAgreementSuccess
  | GenerateAgreementUnSuccess
  | PreviewAgreement
  | PreviewAgreementSuccess
  | PreviewAgreementUnSuccess
  | StatementList
  | StatementListSuccess
  | StatementListUnSuccess
  | GenerateStatement
  | GenerateStatementSuccess
  | GenerateStatementUnSuccess
  | CreditFactor
  | CreditFactorSuccess
  | CreditFactorUnSuccess
  | CashOut
  | CashOutSuccess
  | CashOutUnSuccess
  | InternalAdjustment
  | InternalAdjustmentSuccess
  | InternalAdjustmentUnSuccess
  | PatronNotifList
  | PatronNotifListSuccess
  | PatronNotifListUnSuccess

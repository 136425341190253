import { Component } from '@angular/core'
import { ConfigService } from 'src/app/services/config.service'

@Component({
  selector: 'cui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  metadata
  currentYear: number = new Date().getFullYear()

  version = 'v3.1.2'
  constructor(private config: ConfigService) {
    this.metadata = this.config.getMetadataConfig()
  }
}

import { Injectable } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { Actions, Effect, ofType, OnInitEffects } from '@ngrx/effects'
import { Action, select, Store } from '@ngrx/store'
import { from, Observable, of } from 'rxjs'
import { map, switchMap, catchError, withLatestFrom, concatMap, switchMapTo } from 'rxjs/operators'
import store from 'store'
import { NzNotificationService } from 'ng-zorro-antd'

import * as Reducers from 'src/app/store/reducers'
import * as act from './actions'
import { userManagementService } from 'src/app/services/userManagement'
import * as moment from 'moment'

@Injectable()
export class UserManagementEffects {
  userList: [] = []
  constructor(
    private actions: Actions,
    private userManagementService: userManagementService,
    private router: Router,
    private route: ActivatedRoute,
    private rxStore: Store<any>,
    private notification: NzNotificationService,
  ) {}
  @Effect()
  eventLogList: Observable<any> = this.actions.pipe(
    ofType(act.EVENT_LOG_LIST),
    map((action: act.EventLogList) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.userManagementService.getEventLogList(payload).pipe(
        map(res => {
          res.EventLog = res.data.map(v => {
            v.ActionDate = v.ActionDate ? moment(v.ActionDate).format('YYYY-MM-DD hh:mm:ss A') : ''
            return v
          })
          return new act.EventLogListSuccess(res)
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.EVENT_LOG_LIST_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  getUserList: Observable<any> = this.actions.pipe(
    ofType(act.USER_LIST),
    map((action: act.UserList) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.userManagementService.getUserList().pipe(
        map(res => {
          res.Users = res.Users.map(v => {
            v.Status = v.Status === 'A' ? 'active' : 'inactive'
            v.CreateDate = moment(v.CreateDate).format('YYYY-MM-DD')
            v.LastLogin = v.LastLogin ? moment(v.LastLogin).format('YYYY-MM-DD hh:mm:ss A') : ''
            return v
          })
          return new act.UserListSuccess(res)
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.USER_LIST_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  addUser: Observable<any> = this.actions.pipe(
    ofType(act.ADD_USER),
    map((action: act.AddUser) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([userData, settings]) => {
      return this.userManagementService.addUser(userData).pipe(
        map(res => {
          // this.notification.success('Success', `${res.Message}`)
          return new act.AddUserSuccess(res)
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.ADD_USER_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  updateUser: Observable<any> = this.actions.pipe(
    ofType(act.UPDATE_USER),
    map((action: act.UpdateUser) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([userData, settings]) => {
      return this.userManagementService.updateUser(userData).pipe(
        map(res => {
          this.notification.success('Success', `${res.Message}`)
          return new act.UserList()
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.UPDATE_USER_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  updateGroup: Observable<any> = this.actions.pipe(
    ofType(act.UPDATE_GROUP),
    map((action: act.UpdateGroup) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([userData, settings]) => {
      return this.userManagementService.updateGroup(userData).pipe(
        map(res => {
          // let response = JSON.parse(res)
          this.notification.success('Success', `${res.Message}`)
          return new act.GroupList()
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.UPDATE_GROUP_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  deleteGroup: Observable<any> = this.actions.pipe(
    ofType(act.DELETE_GROUP),
    map((action: act.DeleteGroup) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([groupData, settings]) => {
      return this.userManagementService.deleteGroup(groupData).pipe(
        map(res => {
          // let response = JSON.parse(res)
          this.notification.success('Success', `${res.Message}`)
          return new act.GroupList()
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.DELETE_GROUP_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  deleteUser: Observable<any> = this.actions.pipe(
    ofType(act.DELETE_USER),
    map((action: act.DeleteUser) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([userData, settings]) => {
      return this.userManagementService.deleteUser(userData).pipe(
        map(res => {
          // let response = JSON.parse(res)
          this.notification.success('Success', `${res.Message}`)
          return new act.UserList()
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.DELETE_USER_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  getRoleList: Observable<any> = this.actions.pipe(
    ofType(act.ROLE_LIST),
    map((action: act.RoleList) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.userManagementService.getRoleList().pipe(
        map(res => {
          // return new act.RoleListSuccess(res)
          res.Roles = res.Roles.map(v => {
            v.CreateDate = moment(v.CreateDate).format('YYYY-MM-DD')
            v.UpdateDate = moment(v.UpdateDate).format('YYYY-MM-DD')
            return v
          })
          return new act.RoleListSuccess(res)
        }),
        catchError(error => {
          let err = error.error
          // this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.ROLE_LIST_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  getGroupList: Observable<any> = this.actions.pipe(
    ofType(act.GROUP_LIST),
    map((action: act.GroupList) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.userManagementService.getGroupList().pipe(
        map(res => {
          let data = {}
          data['GroupList'] = res.list.map(v => {
            v.GROUP_CREATE_DATE = moment(v.GROUP_CREATE_DATE).format('YYYY-MM-DD')
            v.GROUP_UPDATE_DATE = moment(v.GROUP_UPDATE_DATE).format('YYYY-MM-DD')
            return v
          })
          return new act.GroupListSuccess(data)
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.GROUP_LIST_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  getModuleList: Observable<any> = this.actions.pipe(
    ofType(act.MODULE_LIST),
    map((action: act.ModuleList) => true),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([payload, settings]) => {
      return this.userManagementService.getModuleList().pipe(
        map(res => {
          res.ModuleList = res.list
          return new act.ModuleListSuccess(res)
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.MODULE_LIST_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  addRole: Observable<any> = this.actions.pipe(
    ofType(act.ADD_ROLE),
    map((action: act.AddRole) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([roleData, settings]) => {
      return this.userManagementService.addRole(roleData).pipe(
        map(res => {
          this.notification.success('Success', `${res.Message}`)
          return new act.RoleList()
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.ADD_ROLE_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  updateRole: Observable<any> = this.actions.pipe(
    ofType(act.UPDATE_ROLE),
    map((action: act.UpdateRole) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([roleData, settings]) => {
      return this.userManagementService.updateRole(roleData).pipe(
        map(res => {
          this.notification.success('Success', `${res.Message}`)
          return new act.AddPermission(roleData)
          // return new act.RoleList()
        }),
        catchError(error => {
          // console.log(error)
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.UPDATE_ROLE_UNSUCCESSFUL }])
        }),
      )
    }),
  )

  @Effect()
  deleteRole: Observable<any> = this.actions.pipe(
    ofType(act.DELETE_ROLE),
    map((action: act.DeleteRole) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([roleData, settings]) => {
      return this.userManagementService.deleteRole(roleData).pipe(
        map(res => {
          this.notification.success('Success', `${res.Message}`)
          return new act.RoleList()
        }),
        catchError(error => {
          let err = error.error
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.DELETE_ROLE_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  addPermission: Observable<any> = this.actions.pipe(
    ofType(act.ADD_PERMISSION),
    map((action: act.AddPermission) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.userManagementService.addPermission(data).pipe(
        map(res => {
          // this.notification.success('Success', `${res.Message}`)
          return new act.RoleList()
        }),
        catchError(error => {
          let err = JSON.parse(error)
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.ADD_PERMISSION_UNSUCCESSFUL }])
        }),
      )
    }),
  )
  @Effect()
  operatorResetPassword: Observable<any> = this.actions.pipe(
    ofType(act.OPS_RESET_PWD),
    map((action: act.OpsResetPwd) => action.data),
    concatMap(action =>
      of(action).pipe(withLatestFrom(this.rxStore.pipe(select(Reducers.getSettings)))),
    ),
    switchMap(([data, settings]) => {
      return this.userManagementService.operatorResetPassword(data).pipe(
        map(res => {
          res.opsResetPwdResult = res
          return new act.OpsResetPwdSuccess(res)
        }),
        catchError(error => {
          let err = JSON.parse(error)
          this.notification.warning('Error', `${err.Message}`)
          return from([{ type: act.OPS_RESET_PWD_UNSUCCESSFUL }])
        }),
      )
    }),
  )
}

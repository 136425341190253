import { Injectable } from '@angular/core'
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { select, Store } from '@ngrx/store'
import * as Reducers from 'src/app/store/reducers'
import store from 'store'
import { LocalService } from 'src/app/services/local.service'
import { NzNotificationService } from 'ng-zorro-antd'
import * as UserActions from 'src/app/store/user/actions'

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  authorized: boolean

  constructor(
    private store: Store<any>,
    public router: Router,
    private localService: LocalService,
    private notification: NzNotificationService,
  ) {
    // this.store.pipe(select(Reducers.getUser)).subscribe(state => {
    //   this.authorized = state.authorized
    // })
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.localService.getJsonValue('SessionId')
    const session_expiry = this.localService.getJsonValue('session_expiry')
    const logTime = this.localService.getJsonValue('log_time')
    let timer = 10
    let dateTimerInterval = timer * 60 * 1000
    let expiry = logTime + dateTimerInterval
    const allow = currentUser && expiry > +new Date().getTime()

    if (allow) {
      // logged in so return true
      return true
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } })
    return false
  }
}
